@import 'intl-tel-input/build/css/intlTelInput.css';
@import "1variables";
@import "2bootstrap-config";
@import "bootstrap-icons/font/bootstrap-icons";

@import "@ng-select/ng-select/themes/default.theme.css";
@import "ngx-toastr/toastr";
@import "bootstrap/scss/bootstrap";

/* Core AG Grid CSS */
@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';
@import "themes/theme-chantier";
@import "themes/theme-iris";

@media (min-width: 2000px) {
    .container {
        max-width: 1740px !important;
    }
}

.lgRed {
    color: $lgRed;
}

.anchor {
    position: absolute;
    top: -64px;
}

*:has(> .anchor) {
    position: relative;
}

.ag-watermark-text, .ag-watermark {
    display: none !important;
}

.modal-md .modal-dialog {
    width: 700px;
    max-width: 600px;
}

.tox-promotion {
    display: none !important;
}

.tox-statusbar__branding {
    display: none !important;
}

body {
    min-height: 100vh;
}

a {
    text-decoration: none;
}

.ng-select {
    &.embedded {
        &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
            border-color: transparent;
            box-shadow: none;
        }

        > .ng-select-container {
            border: 0;

            > .ng-value-container {
                padding: 0;

                > .ng-placeholder {
                    padding-left: 1rem;
                }

                > .ng-value {
                    padding-left: 1rem;
                }

                > .ng-input {
                    padding-left: 1rem;
                }
            }

            &:hover {
                box-shadow: none;
            }

            > .ng-arrow-wrapper {
                display: none;
            }
        }

        > .ng-dropdown-panel {
            border-top: 0;
            border-bottom: 1px solid #dfdfdf;
            border-right: 1px solid #dfdfdf;
            border-left: 1px solid #dfdfdf;
            min-width: 30rem;
            margin-left: -1px;

            .ng-option {
                padding-left: 1rem;
            }
        }
    }

    .ng-select-disabled > .ng-select-container {
        background-color: #e9ecef;
    }

    &.is-invalid .ng-select-container {
        border-color: $lgRed !important;
    }
}

.list-group {
    > .list-group-item {
        min-height: 60px;
        display: flex;
        align-items: center;
    }
}

.fullscreen-image .modal-content {
    background-color: rgba(0, 0, 0, 0.3);
}


.toast-container {
    position: fixed;
}

ag-grid-angular {
    height: 350px;
}

.ag-theme-quartz {

    --ag-header-foreground-color: black;
    --ag-header-background-color: white;
    --ag-header-column-resize-handle-color: black;
    --ag-header-cell-text-font-weight: bold !important;
    --ag-odd-row-background-color: #f9f9f9;
    --ag-even-row-background-color: #ffffff;
    --ag-row-hover-color: #f1f1f1;
    --ag-row-border-style: none;
    --ag-borders-critical: none;
    --ag-page-size-display: none !important;
    --ag-active-color: #676565;
    --ag-input-focus-border-color: #676565;
    --ag-border-color: #676565;
    --ag-font-family: 'Poppins', sans-serif;
    --ag-font-size: 16px;

    .ag-icon-menu::before, .ag-icon-desc::before, .ag-icon-asc::before, .ag-icon-filter::before {
        color: black;
    }

    .ag-header-row {
        border-bottom: 1px solid $lgRed;
    }

    .ag-root-wrapper {
        border-top: none;
        border-right: none;
        border-bottom: none;
        border-left: none;
    }

    .ag-tooltip {
        background-color: #fafafa;
    }

    .ag-header .ag-icon-filter::before {
        color: black !important;
    }

    .ag-paging-panel {
        justify-content: center;
        border-top: none;
    }

    .ag-root-wrapper {
        border-radius: 0;
    }
}



